import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../environments/environment';


interface myData {
  success: boolean,
  message: string
}
@Injectable({
  providedIn: 'root'
})
export class SubmitContactService {

  private baseUrl = environment.apiBaseUrl;
  constructor(private http: HttpClient) { }

  contactInfoSubmit(fname,lname,email,phone,message)
  {
    let httpParams = new HttpParams()
    .append("fname", fname)
    .append("lname", lname)
    .append("email", email)
    .append("phone", phone)
    .append("message", message)
    return this.http.post<myData>(this.baseUrl+'contactSubmit', httpParams)
  }
}
