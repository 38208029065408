import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Observable } from 'rxjs/Observable';

export interface myBlogArr{
  blog_id: number,
  blog_title: string,
  blog_image: string,
  publish_date: string,
  blog_content: string
}
@Injectable({
  providedIn: 'root'
})
export class MyblogsService {
  private baseUrl = environment.apiBaseUrl;
  constructor(private http: HttpClient) { }
  


  getMyBlogs(limit,offset,order): Observable<myBlogArr[]>
  {    
    return this.http.get<myBlogArr[]>(this.baseUrl+'GetBlogs?limit='+limit+'&offset='+offset+'&order='+order)
  }

  getBlogContents(blog_id)
  {    
    return this.http.get(this.baseUrl+'GetBlogContents?blog_id='+blog_id)
  }

}
