import { Component, OnInit } from '@angular/core';
import { TestimonyapiService } from '../testimonyapi.service';

@Component({
  selector: 'app-testimony',
  templateUrl: './testimony.component.html',
  styleUrls: ['./testimony.component.css']
})
export class TestimonyComponent implements OnInit {

  constructor(private mytest: TestimonyapiService) { }

  private allTest: any[];

  ngOnInit() {
    
  	this.mytest.getTestimony('3','','DESC').subscribe(data => {    
      	
      	this.allTest = data;         
    })	
    window['loadCarousel']();
    
  }

}
