import { Component, OnInit } from '@angular/core';
import {MyblogsService } from '../myblogs.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-recent-blog-list',
  templateUrl: './recent-blog-list.component.html',
  styleUrls: ['./recent-blog-list.component.css']
})
export class RecentBlogListComponent implements OnInit {

  constructor(private myblogs: MyblogsService, private router: Router) { }

  private allItems: any[];

  ngOnInit() {
  	this.myblogs.getMyBlogs('3','','DESC').subscribe(data => {    
      	//console.log(data)
      	this.allItems = data;         
    })
  }

  LoadBlogDetail(value)
  {
	localStorage.setItem("blog_id",value)
	this.router.navigate(['blogdetail'])
  }

}
