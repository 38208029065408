import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SubmitContactService } from '../submit-contact.service';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.css']
})
export class ContactFormComponent implements OnInit {
  
 

  constructor(private fb: FormBuilder,private toastr: ToastrService, private cosub: SubmitContactService) { }
 
  
  onSubmit() { 
  	// TODO: Use EventEmitter with form value
  	 
    
   }

    contactSubmit(event){
   	const target = event.target
    const fname = target.querySelector("#fname").value
    const lname = target.querySelector("#lname").value
    const email = target.querySelector("#email").value
    const phone = target.querySelector("#mob").value
    const message = target.querySelector("#des").value
    this.cosub.contactInfoSubmit(fname,lname,email,phone,message).subscribe(data => {
     
       if(data['status'])
       {
         this.toastr.success(data['message'])
         target.querySelector("#fname").value =''
         target.querySelector("#lname").value =''
         target.querySelector("#email").value =''
         target.querySelector("#mob").value =''
         target.querySelector("#des").value =''
       }
       else
       {
        this.toastr.error(data['message'])
       }
     })
   }

  ngOnInit() {
  }

}
