import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Observable } from 'rxjs/Observable';

export interface myTestArr{
  id: number,
  name: string,
  post: string,
  test_image: string,
  message: string
}

@Injectable({
  providedIn: 'root'
})
export class TestimonyapiService {
  private baseUrl = environment.apiBaseUrl;
  constructor(private http: HttpClient) { }
  


  getTestimony(limit,offset,order): Observable<myTestArr[]>
  {    
    return this.http.get<myTestArr[]>(this.baseUrl+'GetTestimony?limit='+limit+'&offset='+offset+'&order='+order)
  }
}
