import { Component, OnInit } from '@angular/core';
import { TestimonyapiService } from '../testimonyapi.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(private mytest: TestimonyapiService) { }

  private allTest: any[];

  ngOnInit() {
    
  	this.mytest.getTestimony('3','','DESC').subscribe(data => {    
      	console.log(data)
      	this.allTest = data;         
    })	
    window['loadCarousel']();
  }


}
