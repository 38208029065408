import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-d-p-a',
  templateUrl: './d-p-a.component.html',
  styleUrls: ['./d-p-a.component.css']
})
export class DPAComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
