import { Component, OnInit } from '@angular/core';
import { MouseEvent } from '@agm/core';




@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

 
  constructor() { }
  
  ngOnInit() {


  }

}
